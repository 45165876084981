import { removeDecimal, removeNonInteger, removeNonNumber, removeLeadingZero } from './numberHelper';

function sanitizeIDRCurrencyValue(value) {
    let sanitizedValue = value.toString();

    sanitizedValue = removeNonNumber(value);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    if (!sanitizedValue && sanitizedValue.length > 1) {
        return 0;
    }

    sanitizedValue = removeLeadingZero(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    return removeNonInteger(sanitizedValue);
}
window.sanitizeIDRCurrencyValue = sanitizeIDRCurrencyValue;

function sanitizeVolumeValue(value) {
    if (!value || isNaN(value)) {
        return 0;
    }

    let sanitizedValue = removeNonNumber(value.toString());

    if (sanitizedValue.charAt(sanitizedValue.length - 1) === '.') {
        return sanitizedValue;
    }

    sanitizedValue = removeLeadingZero(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    return parseFloat(sanitizedValue);
}
window.sanitizeVolumeValue = sanitizeVolumeValue;
