require('./bootstrap');
require('./imageHelper');
require('./videoHelper');
require('./fileHelper');
require('./scrollHelper');
require('./rateLimitHelper');
require('./urlHelper');
require('./inAppHelper');
require('./moment');
require('./priceHelper');
require('./numberHelper');
require('./inputHelper');

document.addEventListener('DOMContentLoaded', function (event) {
    forceInAppToUseBrowser();
});