function putFileToInputElement(file, elementId, triggerChange = true) {
    const element = document.getElementById(elementId);

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    element.files = dataTransfer.files;

    if (triggerChange) {
        const event = new Event('change', { bubbles: true });
        element.dispatchEvent(event);
    }
}
window.putFileToInputElement = putFileToInputElement;
