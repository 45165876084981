function isValidVideoFileFormat(file) {
    const allowedExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'wmf', 'webm'];

    const extension = file.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(extension)) {
        return false;
    }

    return true;
}
window.isValidVideoFileFormat = isValidVideoFileFormat;

function isValidVideoFileSize(file, maxFileSizeByte = undefined) {
    if (maxFileSizeByte === undefined) {
        throw new Error('The maxFileSizeByte argument in isValidVideoFileSize() is mandatory.');
    }

    const fileSizeByte = file.size;

    if (fileSizeByte > maxFileSizeByte) {
        return false;
    }

    return true;
}
window.isValidVideoFileSize = isValidVideoFileSize;
