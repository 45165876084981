import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
window.browser = browser;

function isMobile() {
    const platformType = browser.getPlatformType(true);
    return platformType === "mobile";
}
window.isMobile = isMobile;

function isTablet() {
    const platformType = browser.getPlatformType(true);
    return platformType === "tablet";
}
window.isTablet = isTablet;

function isAndroid() {
    const osName = browser.getOSName(true);
    return osName === "android";
}
window.isAndroid = isAndroid;

function isIos() {
    const osName = browser.getOSName(true);
    return osName === "ios";
}
window.isIos = isIos;

export { isMobile, isTablet, isAndroid, isIos };

