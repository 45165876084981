import { getTimezone } from './dateTimeHelper';

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
const currentUserTimezone = document.querySelector('meta[name="timezone"]')?.getAttribute('content');

async function systemSetUserTimezone() {
    if (! csrfToken) {
        return;
    }

    if (currentUserTimezone) {
        return;
    }

    const newTimezone = getTimezone();

    if (! newTimezone) {
        return;
    }

    if (currentUserTimezone?.toLowerCase() === newTimezone?.toLowerCase()) {
        return;
    }

    const baseUrl = window.location.origin;

    const url = new URL('member/profile/systemSetTimezone', baseUrl);

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            'X-CSRF-TOKEN': csrfToken
        },
        body: JSON.stringify({
            "timezone": newTimezone,
        })
    };

    const response = await fetch(url, options);
}

export { systemSetUserTimezone };