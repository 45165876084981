const { isNumber } = require('lodash');

function removeNonNumber(string) {
    return string.replace(/[^0-9.]/g, '');
}
window.removeNonNumber = removeNonNumber;

function removeNonInteger(string) {
    return string.replace(/[^0-9]/g, '');
}
window.removeNonInteger = removeNonInteger;

function removeLeadingZero(string) {
    if (isNumber(string)) {
        string = string.toString();
    }

    return string.replace(/^0+/, '');
}
window.removeLeadingZero = removeLeadingZero;

function removeDecimal(number) {
    if (isNaN(number)) {
        return 0;
    }

    if (! isNumber(number)) {
        number = parseInt(number);
    }

    return Math.trunc(number);
}
window.removeDecimal = removeDecimal;


export { removeNonNumber, removeNonInteger, removeLeadingZero, removeDecimal };