function getNavbarHeightOffset() {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
        return navbar.offsetHeight;
    }

    return 0;
}
window.getNavbarHeightOffset = getNavbarHeightOffset;

function scrollToElement(elementId, includeNavbarOffset = true) {
    const element = document.getElementById(elementId);
    const scrollPadding = 20;
        
    let navbarOffset = 0;
    if (includeNavbarOffset) {
        navbarOffset = getNavbarHeightOffset();
        console.log('offset', navbarOffset);
    }

    const elementPosition = element.getBoundingClientRect().top + window.scrollY - navbarOffset - scrollPadding;

    window.scrollTo({
        top: elementPosition,
    });
}
window.scrollToElement = scrollToElement;