function isValidImageFileFormat(file) {
    const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];

    const extension = file.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(extension)) {
        return false;
    }

    return true;
}
window.isValidImageFileFormat = isValidImageFileFormat;

function isValidImageFileSize(file, maxFileSizeByte = undefined) {
    if (maxFileSizeByte === undefined) {
        throw new Error('The maxFileSizeByte argument in isValidImageFileSize() is mandatory.');
    }

    const fileSizeByte = file.size;

    if (fileSizeByte > maxFileSizeByte) {
        return false;
    }

    return true;
}
window.isValidImageFileSize = isValidImageFileSize;

function updateImageFilePreview(imageElementId, file) {
    const imageElement = document.getElementById(imageElementId);

    if (!file) {
        imageElement.src = "";
        
        return ;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
        imageElement.src = e.target.result;
    };

    reader.readAsDataURL(file);
}
window.updateImageFilePreview = updateImageFilePreview;